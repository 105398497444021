@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: Arial, Helvetica, sans-serif;
}

.link {
  color: #333;
  text-decoration: 0;
  font-size: 17px;
  font-weight: bolder !important;
}

.link:hover {
  color: #04609A;
}

.eyeball-logo {
  width: 95px;
  height: 75px;
  padding-bottom: 8px;
}

.cms-logo {
  width: 145px;
}

.hr-logo {
  width: 110px;
  height: 90px;
}

.claims-logo {
  width: 100px;
  height: 100px;
  padding-bottom: 8px;
}

.quoting-logo {
  width: 180px;
  height: 140px;
}